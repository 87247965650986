const getDate = (date, separated = '-', notFullYear = false) => {
    if (date instanceof Date) {
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()

        if (notFullYear) year = year.toString().slice(2, 4)
        if (month < 10) month = `0${month}`
        if (day < 10) day = `0${day}`

        return `${year}${separated}${month}${separated}${day}`
    } else return '';
}

const getDateTime = (dateTime) => {
    if (dateTime instanceof Date) {
        let month = dateTime.getMonth() + 1;
        let day = dateTime.getDate()
        let hour = dateTime.getHours()
        let minutes = dateTime.getMinutes()

        if (month < 10) month = `0${month}`
        if (day < 10) day = `0${day}`
        if (hour < 10) hour = `0${hour}`
        if (minutes < 10) minutes = `0${minutes}`

        return `${dateTime.getFullYear()}-${month}-${day} ${hour}:${minutes}`
    } else return '';
}

const getDateTimeSec = (dateTime) => {
    if (dateTime instanceof Date) {
        let month = dateTime.getMonth() + 1;
        let day = dateTime.getDate()
        let hour = dateTime.getHours()
        let minutes = dateTime.getMinutes()
        let second = dateTime.getSeconds()

        if (month < 10) month = `0${month}`
        if (day < 10) day = `0${day}`
        if (hour < 10) hour = `0${hour}`
        if (minutes < 10) minutes = `0${minutes}`
        if (second < 10) second = `0${second}`

        return `${dateTime.getFullYear()}-${month}-${day} ${hour}:${minutes}:${second}`
    } else return '';
}

module.exports = {
    getDate,
    getDateTime,
    getDateTimeSec
}
